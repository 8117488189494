<template>
  <section class="transfer-naira" v-loading="loading">
    <form class="form" @submit.prevent="submitForm" novalidate="true" autocomplete="off">
      <div class="form__group--three">
        <label for="amount" class="form__label"> </label>
        <input
          type="text"
          id="amount"
          name="allowanceType"
          placeholder="Amount"
          class="form__input"
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          v-model.lazy="$v.form.amount.$model"
          :class="{
            'is-invalid': submitted && $v.form.amount.$error,
          }"
        />
        <small class="error" v-if="submitted && !$v.form.amount.required"
          >Enter an Amount</small
        >
      </div>
      <div class="form__group--three">
        <label for="destinationID" class="form__label"> </label>
        <input
          readonly
          type="text"
          id="destinationID"
          name="destinationID"
          placeholder="Destination ID"
          class="form__input"
          v-model.lazy="$v.form.destinationID.$model"
          :class="{
            'is-invalid': submitted && $v.form.destinationID.$error,
          }"
        />
        <small class="error" v-if="submitted && !$v.form.destinationID.required"
          >Destination ID is required</small
        >
      </div>
      <div class="form__group--three">
        <label for="sourceAccount" class="form__label"> </label>
        <input
          readonly="readonly"
          type="text"
          id="sourceAccount"
          name="sourceAccount"
          placeholder="Source Account"
          class="form__input"
          v-model.lazy="$v.form.sourceAccount.$model"
          :class="{
            'is-invalid': submitted && $v.form.sourceAccount.$error,
          }"
        />
        <small class="error" v-if="submitted && !$v.form.sourceAccount.required"
          >Source Account is required</small
        >
      </div>
      <button class="proceed-btn">
        <span>Withdraw</span>
      </button>
    </form>
    <app-pin-modal
      ref="otpInput"
      v-if="showPinModal"
      :showPinModal="showPinModal"
      @close="showPinModal = false"
      @userPin="setUserPin"
    ></app-pin-modal>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import PinModal from "@/widgets/PinModal.vue";
import api from "@/api/api.js";
export default {
  components: {
    "app-pin-modal": PinModal,
  },
  data() {
    return {
      submitted: false,
      showPinModal: false,
      transactionPin: "",
      form: {
        destinationID: "",
        amount: "",
        sourceAccount: "",
      },
      loading: false,
      guid: "",
    };
  },
  computed: mapState(["userDetailsTwo", "user"]),
  validations: {
    form: {
      amount: { required },
      destinationID: { required },
      sourceAccount: { required },
    },
  },
  mounted() {
    if (this.$store.state.eNairaUserData.accountType == "commerce") {
      this.form.destinationID = this.$store.state.eNairaUserBalance.walletAddress;
      this.form.sourceAccount = this.user.profileInfo.PryAccount;
      this.guid = this.$store.state.eNairaUserBalance.walletAddress;
    } else {
      this.form.destinationID = this.$store.state.eNairaUserBalance.guid;
      this.form.sourceAccount = this.user.profileInfo.PryAccount;
      this.guid = this.$store.state.eNairaUserBalance.guid;
    }
  },

  methods: {
    submitForm(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }
      this.showPinModal = true;
    },
    eNairaCommerceWithdrawal() {
      let payload = {
        amount: this.form.amount,
        sourceCommerceWalletGuid: this.guid,
        destinationBranchWalletGuid: this.form.destinationID,
        destinationFundsType: "CASH",
        referenceId: "Withdraw",
        SMID: this.user.profileInfo.SMID,
        token: this.$store.state.eNairaUserData.enairaUser.token,
        ToAccount: this.user.profileInfo.PryAccount,
        TransactionPin: this.transactionPin,
        CURRENCYCODE: "NGN",
        SendToMySelf: "true",
      };
      api
        .commerceCreateWithdrawalRequest(payload)
        .then((response) => {
          if (response.Status) {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `Transaction processed successfully`,
              type: "success",
              duration: 6000,
            });
            setTimeout(() => {
              this.$router.push("/e-naira-transactions");
            }, 7000);
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `${response.Message}`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Withdrawal request could not be processed`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    EnableMerchantWithdrawal() {
      let payload = {
        token: this.$store.state.eNairaUserData.enairaUser.token,
      };
      api
        .enableMerchantWithdrawal(payload)
        .then((response) => {
          if (response) {
            if (response.Status) {
              this.eNairaCommerceWithdrawal();
            } else {
              this.loading = false;
              this.$message({
                showClose: true,
                message: `User could not be authenticated for this transaction`,
                type: "error",
                duration: 10000,
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Withdrawal not enabled`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    EnableCommerceWithdrawal() {
      let payload = {
        token: this.$store.state.eNairaUserData.enairaUser.token,
      };
      api
        .enableCommerceWithdrawal(payload)
        .then((response) => {
          if (response) {
            if (response.Status) {
              this.eNairaCommerceWithdrawal();
            } else {
              this.loading = false;
              this.$message({
                showClose: true,
                message: `User could not be authenticated for this transaction`,
                type: "error",
                duration: 10000,
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Withdrawal not enabled`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    setUserPin(value) {
      this.loading = true;

      this.showPinModal = false;
      let payload = {
        userId: this.user.profileInfo.SMID,
        pin: value,
      };
      this.transactionPin = value;
      api
        .handlePinValidation(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let feedback = response.Data;
            if (feedback.isvalid) {
              if (this.$store.state.eNairaUserData.accountType == "commerce") {
                this.EnableCommerceWithdrawal();
              } else {
                this.EnableMerchantWithdrawal();
              }
            }
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error validating pin`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.service-offer {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #000000;
}
.service-list {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 4em;
  color: #606062;
  list-style: none;
  padding-top: 2em;
}
.service-list li::before {
  content: "\2022";
  color: #66cc15;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 15px;
}
.pta-select {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  widows: 100%;
}
#open-sterling {
  color: #ffffff;
  background: #db353a;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px;
}
.non-sterling {
  margin-right: auto;
}
.proceed-btn {
  color: #ffffff;
  background: linear-gradient(180deg, #013d27 0%, #00492e 100%);
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1.2rem 3.8rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
}
.transfer-naira {
  max-width: 70rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  margin: 12rem auto;
  padding: 5rem;
}
</style>
